@font-face {
  font-family: "Clear Sans";
  src: url("ClearSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Clear Sans";
  src: url("ClearSans-Bold.woff") format("woff");
  font-weight: 700;
}

html {
  background-color: rgb(18, 18, 19);
  color: rgb(215, 218, 220);
  font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;
  text-align: center;
}

a {
  color: #fff;
}

a:hover,
a:focus {
  color: #d8d8d8;
}

.tile {
  align-items: center;
  background-color: #538d4e;
  color: #d8d8d8;
  display: inline-flex;
  font-size: 2rem;
  font-weight: 700;
  height: 62px;
  justify-content: center;
  line-height: 32px;
  text-transform: uppercase;
  vertical-align: middle;
  width: 62px;
}

.top-space {
  margin-top: 100px;
}

.list {
  padding: 0;
}

.list li {
  list-style: none;
}
